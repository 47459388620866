import logo from './logo.svg';
import './App.css';
import Scan from './components/Scan';
import { Routes, Route, Link } from "react-router-dom";
import Security from './components/Security';
import Nav from './components/Nav';
import Basic from './components/Basic';
import Login from './components/auth/Login';
import SignUp from './components/auth/SingUp';
import Landing from './components/auth/Landing';
import { createContext, useReducer } from 'react';
import Dashboard from './components/Dashboard';
import Logout from './components/auth/Logout';
import JitsiSDK from './components/Conference/JitsiSDK';
import NewUI from './components/nav/DocSideBar';
import DocDashboard from './components/doctor/DocDashboard';
import Patients from './components/doctor/Patients';
import DocMeeting from './components/doctor/DocMeeting';
import Appointments from './components/doctor/Appointments';
import Doctor from './components/doctor/Doctor';
import Patient from './components/doctor/Patient';

export const AuthContext = createContext()

const initialauth = {
  logged_in: sessionStorage.getItem('logged_in',false),
   user: sessionStorage.getItem('user'),
   token: sessionStorage.getItem('token'),
   userid: sessionStorage.getItem('userid'),
}

const authreducer = (state, action) => {
  switch (action.type) {

    
    case 'change_language':
     
          return {...state,preffered_language : action.value}
    case 'login_status':
          return {...state,logged_in : action.value}
    case 'user':
      return {...state, user: action.value}
    case 'token':
      return {...state, token: action.value}
    case 'userid':
      return {...state, userid: action.value}
    
    default:
      return state
  }
};

function App() {
  const [auth, dispatchAuth] = useReducer(authreducer, initialauth)
  return (
    <AuthContext.Provider value={{ auth, dispatchAuth }}>
    <div>
      {/* <Scan /> */}
      {/* <Nav /> */}
      <Routes>
      <Route path="/" element={<Login />} />
      <Route path='/login' element={<Login />} />
      {/* <Route path='/signup' element={<SignUp />} /> */}
      <Route path='/dashboard' element={<Doctor />} />
      <Route path='/logout' element={<Logout />} />
      <Route path='/patients' element={<Patient />} />
      {/* <Route path='/newui' element={<NewUI />} /> */}
      {/* <Route path='/doc/dashboard' element={<DocDashboard />} />
      <Route path='/doc/patients' element={<Patients />} />
      <Route path='/doc/appointments' element={<Appointments />} /> */}
      {/* <Route path='/meeting' element={<JitsiSDK />} /> */}
      {/* <Route path='/meeting/:user/:doctor/:meetingid/:nodid' element={<DocMeeting />} /> */}
      {/* <Route path='/Landing' element={<Landing />} /> */}
      {/* <Route path='/scan' element={<Security />} /> */}

        {/* <Route path="/security" element={<Security />} /> */}
      </Routes>
     
    </div>

    </AuthContext.Provider>
    
  );
}

export default App;
