import React, { useEffect,useContext, useState } from 'react';
import { AuthContext } from '../../App';
import { Link, Navigate } from 'react-router-dom';

const Logout = () => {
    const [success, setSuccess]=useState(false)
    const {auth, dispatchAuth } = useContext(AuthContext)
    useEffect(()=>{
        sessionStorage.clear()
        dispatchAuth({type:'login_status',value:false})
        dispatchAuth({type:'user',value:{}})
        dispatchAuth({type:'token',value:''})
        dispatchAuth({type:'userid',value:''})
        setSuccess(true)


    },[])
    return ( <>
    {success ? <>
      <Navigate to="/" />
    
    </> : <></>}
    
    </> );
}
 
export default Logout;